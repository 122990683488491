
import { AtlasPageHeader, CampusCardList, CampusHeading } from 'atlas-ds'
import { linkConstructor } from 'components/link'
import ModuleCard from 'components/module-card'
import Results from 'components/results'
import { useAPIModulesHome } from 'services/client'
import Template from 'template'
import { useEntreprise } from './_app'

export default function HomePage() {
    const entreprise = useEntreprise()
    const branche = entreprise?.branche
    const moreCta = process.env.NEXT_PUBLIC_FNE === "true" ? {
        href: '/fne-formation',
        label: 'Consulter le catalogue FNE',
        linkConstructor: linkConstructor
    } : {
        href: branche ? `/branches/${branche.path}/${branche.id}` : '/branches',
        label: 'Consulter le catalogue',
        linkConstructor: linkConstructor
    }

    const PAGE_SIZE = 5

    const { data, count, error, loading } = useAPIModulesHome(PAGE_SIZE, branche)

    return <Template
        pageName="home"
        showIntro={true}
        pageHeader={<AtlasPageHeader>
            <CampusHeading tag="h2" type="module">
                { process.env.NEXT_PUBLIC_FNE === "true" ? "Modules éligibles au FNE" : "Modules" }
            </CampusHeading>
        </AtlasPageHeader>}
    >
        <Results
            hideHeader
            count={ count ?? 0 }
            pageSize={ PAGE_SIZE }
            loading={ loading }
            error={ error }
        >
            <CampusCardList moreCta={ moreCta }>
                {data?.map(module => <ModuleCard key={ module.id } module={ module } />) ?? []}
            </CampusCardList>
        </Results>
    </Template>
}
